<template>
    <div class="app-footer">
        <div class="clearfix"></div>
        <div class="footer-wrapper">
            <div class="footer-logo">
                <img src="../static/img/logo/footer_logo.png" alt="footer_logo">
            </div>
            <div class="footer-group">
                <div class="footer-top">
                    <div class="footer-item">
                        <router-link class="footer-title" to="/book/all">Books</router-link>
                        <router-link class="footer-subtitle" to="/book/catalogs">Catalogs</router-link>
                        <router-link class="footer-subtitle" to="/book/all">All</router-link>
                    </div>
                    
                    <div class="footer-item">
                        <router-link class="footer-title" to="/about/tjup">About</router-link>
                        <router-link class="footer-subtitle" to="/about/tjup">TJUP</router-link>
                        <router-link class="footer-subtitle" to="/news/all">News</router-link>
                        <router-link class="footer-subtitle" to="/jobs">Jobs</router-link>
                    </div>

                    <div class="footer-item">
                        <router-link class="footer-title" to="/links">Links</router-link>
                        <router-link class="footer-subtitle" to="/links#englishJournals">English Journals</router-link>
                        <router-link class="footer-subtitle" to="/links#tongjiUniversity">Tongji University</router-link>
                    </div>

                    <div class="footer-item">
                        <router-link class="footer-title" to="/contact">Contact</router-link>
                        <router-link class="footer-subtitle" to="/contact#generalContact">General Contact</router-link>
                        <router-link class="footer-subtitle" to="/contact#tjupInternationalOffice">TJUP International Office</router-link>
                    </div>
                </div>
                <div class="footer-bottom">
                    <div class="footer-copyright">
                        <span> © 2018 TongJi University Press </span> <a href="http://beian.miit.gov.cn/">沪ICP备11031396号-4</a>
                    </div>
                    <ul>
                        <li>
                            <router-link to="/facebook">
                                <img src="../static/img/icons/facebook.png" alt="facebook">
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/twitter">
                                <img src="../static/img/icons/twitter.png" alt="twitter">
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/google">
                                <img src="../static/img/icons/google.png" alt="google">
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/instagram">
                                <img src="../static/img/icons/instagram.png" alt="instagram">
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/dribbble">
                                <img src="../static/img/icons/dribbble.png" alt="dribbble">
                            </router-link>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="clearfix"></div>
        </div>
    </div>
</template>

<script>
export default {
    
}
</script>


<style lang="stylus" scoped>
$background-color = #F2F2F2
$border-color = #979797
$font-color = #000

.app-footer
    padding 40px 15px
    background-color $background-color

    .clearfix
        clear both
        overflow hidden
        
    .footer-wrapper
        width 1255px
        text-align center
        margin 0 auto

        .footer-logo
            float left
            img
                display block
                width 90px

        .footer-group
            float right
            width 945px
            .footer-top
                display inline-block
                width 895px
                margin 0 25px
                
                .footer-item
                    float left
                    width 25%
                    a
                        display block
                        text-align left 
                        text-decoration none
                        color $font-color                        
                        &:hover
                            color $border-color
                    .footer-title
                        font-family SourceSansPro-Bold
                        font-weight bold
                        line-height 42px
                        font-size 18px
                    
                    .footer-subtitle
                        font-family SourceSansPro-Regular
                        color #231916
                        font-size 16px
                        line-height 25px

            .footer-bottom
                display inline-block
                width 895px
                margin 0 25px
                padding 60px 0 20px 0

                .footer-copyright
                    float left
                    font-family SourceSansPro-Regular
                    font-size 16px
                    color $font-color 
                ul
                    padding 0
                    margin 0
                    float right
                    li
                        list-style none
                        float left
                        margin 0 10px
                        a
                            img
                                display block
                                height 19px

        .clearfix
            clear both
            overflow hidden

</style>

