<template>
    <div class="app-index">
        <!-- header -->
        <app-header></app-header>
        <div class="index-wrapper">
            <!-- banner -->
            <index-banner></index-banner>
            <!-- divider -->
            <div class="index-divider"></div>
            <!-- booklist -->
            <ul class="book-list">
                <li v-for="book in books" :key="book.pk">
                    <book-item :book="book"></book-item>
                </li>
            </ul>
            <div class="clearfix"></div>
        </div>
        <!-- footer -->
        <app-footer></app-footer>
    </div>
</template>

<script>
 

import appHeader from '@/components/AppHeader';
import appFooter from '@/components/AppFooter';
import indexBanner from '@/components/IndexBanner';
import bookItem from '@/components/book/BookItem';
import bookApi from '../api/book'


export default {
    name: 'index',
    metaInfo: {
        title: 'Home',
        titleTemplate: '%s - Tongji University Press',
        meta: [
            {name: 'keywords', content: 'Tongji University Press'},
            {name: 'description', content: 'Tongji University Press'}
        ]
    },
    
    data () {
        return {
            books: [
                 
            ]
        }
    },
    components: {
        appHeader,
        appFooter,
        indexBanner,
        bookItem
    },
    mounted: function(){
        this.getLatestBooks();
    },
    methods: {
        toBookList: function () {

        },
        getLatestBooks:function(){
            let promise = bookApi.getLatestBooks();
            let self = this;
            promise.then(function(response){ 
                let book_list = response.data.data;
                self.books = book_list

            }, function(error){
              console.log(error); 
              alert('获取列表失败' + error.response.data.error)
            });
        }
    }
};
</script>

<style lang="stylus" scoped>
$border-color = #000

.app-index
    .index-wrapper
        width 1035px
        margin 40px auto 0

        .index-divider
            margin 50px auto
            border 1px $border-color solid

        .book-list
            padding 0
            li
                float left
                list-style none
                margin 0 9.375px 60px 9.375px

        .clearfix
            clear both
            overflow hidden

</style>
