<template>
    <div class="book-item"> 
        <router-link :to="'/book/detail?bookId=' + book.id"> 
            <img v-if=book.cover :src="book.cover" alt="book-image">
            <img v-else :src="defaultBookCover" alt="book-image">
        </router-link>
        <div class="book-title" v-text="book.title"></div>
    </div>
</template>

<script>
export default {
    props: {
        book: {},  
    },
    data () {
        return { 
            defaultBookCover: require('@/assets/book.png')
        }
    }
}
</script>

<style lang="stylus" scoped>

$font-color = #000

.book-item
    a
        display inline-block
        width 240px
        height 356px
        overflow hidden
        img
            width 100% 
            transition all 0.8s
            &:hover
                transform scale(1.08)

    .book-title
        font-family SourceSansPro-SemiBold
        text-align left 
        margin-top 10px
        font-size 18px
        width 240px 
        height 60px
        font-color $font-color    
</style>


