<template>
    <div class="index-banner">
        <!-- vue transition-group 组件 -->
        <transition-group tag='ul' class="slide" name='image'>
            <li v-for='(image, index) in imgArr' :key='index' v-show='index === mark'>
                <a href="javascript:;"><img :src="image"></a>
            </li>
        </transition-group>

        <!-- bullet 小圆点 -->
        <div class="bullet">
            <!-- prev btn -->
             <a href="javascript:;" @click="prev()" class="prev">
                <img src="../static/img/icons/left_arrow.png" alt="left-arrow">
            </a>
            <!-- bullet btn -->
            <span v-for='(item,index) in imgArr.length' :key='index' :class="{'active':index===mark}" @click='change(index)'></span>
            <!-- next btn -->
            <a href="javascript:;" @click="next()" class="next">
                <img src="../static/img/icons/right_arrow.png" alt="right-arrow">
            </a>
        </div>

    </div>
</template>

<style lang="stylus" scoped>
.index-banner
    position relative
    width 1035px
    height 350px
    overflow hidden
    ul
        margin 0
        padding 0
        list-style none
        li
            position absolute
            list-style none
            display block
            a
                display inline-block
                img
                    display block
                    width 1035px

    .bullet
        width 100%
        height 50px
        line-height 50px
        position absolute
        bottom 0
        margin 0 auto
        text-align center
        z-index 100
        span
            display inline-block
            cursor pointer
            width 10px
            height 10px
            border-radius 50%
            background-color white
            margin 0 10px

        .active
            background-color #979797

        .prev
            position absolute
            left 20px
            img
                width 27px

        .next
            position absolute
            right 20px
            img
                width 27px

.image-enter-active
    transform translateX(0)
    transition all 1s ease

.image-leave-active
    transform translateX(-100%)
    transition all 1s ease
        
.image-enter
    transform translateX(100%)
        
.image-leave
    transform translateX(0)        
</style>

<script>
export default {
    data () {
        return {
            imgArr: [
                require('@/assets/banner1.png'),
                require('@/assets/banner2.png'),
                require('@/assets/banner3.png'),
                require('@/assets/banner4.png'),
                require('@/assets/banner5.png')
            ],
            mark: 0,            
        }
    },
    created () {
        this.autoPlay()
    },
    methods: {
        change (i) {
            this.mark = i
        },
        autoPlay () {
            // 5s自动轮播
            setInterval(() => {
                this.mark++
                if (this.mark === this.imgArr.length) {
                    this.mark = 0
                }
            }, 5000)
        },
        next () {
            this.mark++
            if (this.mark === this.imgArr.length) {
                this.mark = 0
            }
        },
        prev () {
            this.mark --
            if (this.mark == -1) {
                this.mark = this.imgArr.length - 1
            }
        },
    }
}
</script>

