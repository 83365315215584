/**
 * Created by tolerious on 2017/10/25.
 */
console.log(process.env.NODE_ENV)
let config
if (process.env.NODE_ENV == 'development') {
   config = {
    API_HOST: 'https://localhost:9890', 
  }
} else {
    config = {
    API_HOST: 'https://press.tongji.edu.cn/english', 
  }
} 
export default config