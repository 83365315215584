<template>
    <div class="app-header">
        <div class="header-wrapper">
            <div class="app-logo">
                <router-link to="/">
                    <img src="../assets/logo/logo.png" alt="logo">
                </router-link>
                <ul>
                    <li>
                        <router-link to="/book/menu">
                            Books
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/about/menu">
                            About
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/links">
                            Links
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/contact">
                            Contact
                        </router-link>
                    </li>
                </ul>
            </div>
            <div class="app-share">
                <ul>
                    <li>
                        <router-link to="/search">
                            <img src="../static/img/icons/search.png" alt="search_logo">
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/wechat">
                            <img src="../static/img/icons/wechat.png" alt="wechat_logo">
                        </router-link>
                    </li>
                    <li>
                        <router-link to="http://www.tongjipress.com.cn">
                            <img src="../static/img/icons/chinese.jpeg" alt="chinese_logo">
                        </router-link>
                    </li>
                    <li>
                        <router-link to="http://www.linkedin.com/company/tongjiuniversitypress/">
                            <img src="../static/img/icons/linked-in.png" alt="linked_in_logo">
                        </router-link>
                    </li>
                </ul>
            </div>

            <div class="clearfix"></div>
        </div>

    </div>
</template>


<style lang="stylus" scoped>

$border-color = #979797
$font-color = #000


.app-header
    position relative
    font-family SourceSansPro-Bold
    width 100%
    height 130px
    line-height 130px
    border-bottom 1px $border-color solid
    background-color #FFF
    z-index 1000

    .header-wrapper
        width 1255px
        text-align center
        margin 0 auto

        .app-logo
            float left
            a
                img
                    display inline-block
                    width 160px
                    vertical-align middle

            ul
                margin 0 20px
                padding 0
                display inline-block
                li
                    margin 0 30px
                    display inline-block
                    list-style none
                    a
                        text-decoration none
                        font-size 18px
                        font-weight: bold;
                        color $font-color
                        &:hover
                            color $border-color


        .app-share
            float right
            ul
                margin 0
                padding 0
                display inline-block
                li
                    margin 0 6px
                    display inline-block
                    list-style none
                    a
                        text-decoration none
                        img
                            width 23px
                            height 23px
                            vertical-align middle

        .clearfix
            clear both
            overflow hidden
</style>
