import axios from 'axios'

import config from '../config';

export default{
  getLatestBooks () {   
    let apiUrl = `${config.API_HOST}/book/latest`;
    console.log(apiUrl); 
    let promise = axios.get(apiUrl);
    return promise;
  },
  getBookCategory (catId) {   
    let apiUrl = `${config.API_HOST}/book/category?catId=${catId}`; 
    let promise = axios.get(apiUrl);
    return promise;
  },
  getRecentBook () {   
    let apiUrl = `${config.API_HOST}/book/keybook`; 
    let promise = axios.get(apiUrl);
    return promise;
  },
  searchBooks (keywords, page, limit) {   
    let apiUrl = `${config.API_HOST}/book/search?keywords=${keywords}&p=${page}&limit=${limit}`;
    console.log(apiUrl); 
    let promise = axios.get(apiUrl);
    return promise;
  },
  bookIndex () {   
    let apiUrl = `${config.API_HOST}/book/index`;
    console.log(apiUrl); 
    let promise = axios.get(apiUrl);
    return promise;
  },
  bookList (catId) {   
    let apiUrl = `${config.API_HOST}/book/list?catid=${catId}`;
    console.log(apiUrl); 
    let promise = axios.get(apiUrl);
    return promise;
  },
  getBookDetail (bookId) { 
    let apiUrl = `${config.API_HOST}/book/detail?id=${bookId}`;
    console.log(apiUrl); 
    let promise = axios.get(apiUrl);
    return promise;
  }
};